let currentUser = null, signedIn = false;

const dataEl = document.getElementsByName('wealthbar-user')[0]
if (dataEl) {
  try {
    const data = dataEl.getAttribute('data');
    currentUser = JSON.parse(data);
    signedIn = true;
  } catch {}
}

// legacy support for window.currentUser and window.signedIn
window.currentUser = currentUser;
window.signedIn = signedIn;

export default {
  ...currentUser,
  signedIn,
};
